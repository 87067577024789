import { css } from '@emotion/core';
import { Link } from 'gatsby';

export default ({ prev, next, prefix }) => (
  <nav
    className="pagination is-small"
    role="navigation"
    aria-label="pagination"
    css={css`
      position: absolute;
      bottom: 0;
      display: flex;
      justify-content: space-between;
      width: 100%;
      a {
        margin: 2rem;
      }
    `}
  >
    <Link
      to={prev ? `/${prefix}/${prev.slug.current}` : `/${prefix}`}
      className="pagination-previous"
    >
      {prev ? `← ${prev.title}` : 'Zur Übersicht'}
    </Link>

    {(prev || next) && (
      <Link
        to={next ? `/${prefix}/${next.slug.current}` : `/${prefix}`}
        className="pagination-next"
      >
        {next ? `${next.title} →` : 'Zur Übersicht'}
      </Link>
    )}
  </nav>
);
