import { Link, useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { css } from '@emotion/core';

export default ({ small }) => {
  const { user } = useStaticQuery(graphql`
    {
      user: file(relativePath: { eq: "index/user.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <div
      className="container is-hidden-mobile"
      css={css`
        width: ${small ? '60%' : '100%'};
        margin: ${small ? '3vh' : 'auto'} auto;
      `}
    >
      <div className="box">
        <article className="media">
          <div className="media-left">
            <Img
              fluid={user.childImageSharp.fluid}
              css={css`
                height: ${small ? '80px' : '128px'};
                width: ${small ? '80px' : '128px'};
                border-radius: 50%;
              `}
            />
          </div>
          <div className="media-content">
            <div className="content">
              <p className={`title is-size-${small ? '6' : '5'}`}>
                {small ? 'Über den Autor' : 'Dürfen wir vorstellen'}:{' '}
                {!small && <span className="underlined">Karsten</span>}
              </p>
              <p className={`is-size-${small ? '7' : '6'}`}>
                Karsten ist bereits seit 2007 in der Reisebranche aktiv.
                Ursprünglich wollte er die Menschen dazu motivieren mehr Sport
                zu machen und Sportlern attraktive Reiseziele anzubieten.
                Mittlerweile organisiert er selber Reisen in der ganzen Welt und
                das nicht nur für Sportler & Aktive...
                <Link to="/about">
                  <span className={`is-size-${small ? '7' : '6'}`}>
                    Mehr lesen
                  </span>
                </Link>
              </p>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
};
