import React from 'react';
import { graphql } from 'gatsby';
import { css } from '@emotion/core';
import Layout from '../components/Layout';
import Content from '../components/Content';
import ImageHeader from '../components/ImageHeader';
import Pagination from '../components/Pagination';
import About from '../components/About';
import SEO from '../components/Seo';

export default ({
  data: { sanityPost: post },
  pageContext: { previous, next },
}) => (
  <Layout>
    <SEO title={post.title} fluid={post.mainImage?.asset.fluid} />
    <ImageHeader
      title={post.title}
      size="medium"
      fluid={post.mainImage?.asset.fluid}
    />
    <div
      className="container content"
      css={css`
        margin-top: 5vh;
      `}
    >
      <p className="has-text-grey is-size-7">
        Veröffentlicht am: {post.publishedAt}
      </p>
      <Content content={post._rawBody} />
    </div>
    <About small />
    <Pagination prev={previous} next={next} prefix="blog" />
  </Layout>
);

export const query = graphql`
  query($slug: String!) {
    sanityPost(slug: { current: { eq: $slug } }) {
      _rawBody
      title
      publishedAt(formatString: "DD.MM.YYYY")
      mainImage {
        asset {
          fluid(maxWidth: 1024) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;
